import React from 'react';
import PropTypes from 'prop-types';
import {
  Item,
  ItemContainer,
  Footer,
  Description,
  EventName,
  Title,
} from 'UI/apps/PromotionsGridApp/PromotionsGridReact';
import { A } from 'CORE__UI/apps/PromotionsGridApp/core__promotions-grid-react';
import { emitSelectionIdForBetSlip } from '../../../../EventsApp/component/core__eventsFunction';
import { DynamicPromotionsItem } from './core__promotions-dynamic';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { SYMBOLS } from 'Services/session/core__session.utils';
import { HanldeCMSCurrencySymbol } from 'Services/global/core__currency-format';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import replaceAnd from 'Services/translations/core__replaceAnd';

/**
 * ContactUs App
 * @param {array} promoConfig
 */

const PromotionsDisplay = ({
  promoConfig,
  getBetslipSelectionList,
  index,
  oddsType,
  hideCategories,
  dataFromSocket,
  currencyCode,
  fallbackUrl,
  disableLeftMargin,
}) => {
  /**
   * Method to add/remove bets
   * @param { number } selectionId
   */
  const callBetslip = selectionId => {
    emitSelectionIdForBetSlip(selectionId);
  };
  /**
   * Method to redirect to promo URL
   * @param { object } promoConfig
   */
  const navigateToPage = promoConfig => {
    if (!promoConfig['selectionId'] && !promoConfig.externallink) {
      window.location.href = promoConfig.url;
    } else if (promoConfig.externallink && promoConfig.url) {
      PubSub.emit(PubsubEvents.PARENT_NAVIGATION, promoConfig.url);
    }
  };

  // to make the currency symbol dynamic
  const symbol = SYMBOLS[currencyCode];

  // to raise lighthouse accessibility score
  let promoTitle = '';
  if (promoConfig['title']) {
    promoTitle = promoConfig['title'].toString();
  } else {
    promoTitle = GLOBAL_CONSTANTS.UNAVIALABLE_PROMO;
  }
  const promoDesc = HanldeCMSCurrencySymbol(promoConfig['description'], symbol);
  return (
    <ItemContainer
      hideCategories={hideCategories}
      disableLeftMargin={disableLeftMargin}
    >
      {promoConfig?.type === 'dynamic' ? (
        <DynamicPromotionsItem
          promoConfig={{ ...promoConfig }}
          getBetslipSelectionList={getBetslipSelectionList}
          index={index}
          oddsType={oddsType}
          callBetslip={callBetslip}
          dataFromSocket={
            dataFromSocket && dataFromSocket[promoConfig.marketid]
          }
        />
      ) : (
        <React.Fragment>
          <A
            aria-label={promoTitle}
            onClick={() => {
              navigateToPage(promoConfig);
            }}
          >
            <Item
              hideCategories={hideCategories}
              image={promoConfig['background-image']}
              size={promoConfig['background-size']}
              position={promoConfig['background-position']}
              onClick={() => {
                promoConfig['selectionId'] &&
                  callBetslip(promoConfig['selectionId']);
              }}
              pointer={promoConfig.url || promoConfig.selectionId || false}
            >
              {promoConfig['event-name'] && (
                <EventName>{promoConfig['event-name']}</EventName>
              )}
              {promoConfig['title'] && (
                <Title
                  color={
                    promoConfig['title-color'] &&
                    promoConfig['title-color'] !== '' &&
                    promoConfig['title-color']
                  }
                >
                  {replaceAnd(promoConfig['title'])}
                </Title>
              )}
              <Description>
                {promoConfig.descriptioninhtml ? (
                  <div dangerouslySetInnerHTML={{ __html: promoDesc }}></div>
                ) : (
                  HanldeCMSCurrencySymbol(promoConfig['description'], symbol)
                )}
              </Description>
            </Item>
          </A>
          <Footer
            hideCategories={hideCategories}
            aria-label={HanldeCMSCurrencySymbol(promoTitle, symbol)}
            href={promoConfig.footerurl || fallbackUrl}
          >
            {HanldeCMSCurrencySymbol(promoConfig['footertext'], symbol)}
          </Footer>
        </React.Fragment>
      )}
    </ItemContainer>
  );
};

export { PromotionsDisplay };

PromotionsDisplay.propTypes = {
  promoConfig: PropTypes.object,
  getBetslipSelectionList: PropTypes.array,
  oddsType: PropTypes.string,
  index: PropTypes.number,
  hideCategories: PropTypes.bool,
  dataFromSocket: PropTypes.object,
  currencyCode: PropTypes.string,
  fallbackUrl: PropTypes.string,
  disableLeftMargin: PropTypes.bool,
};
