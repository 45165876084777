import React from 'react';
import styled from 'styled-components';
import EA from 'UI/globals/ExpandArrow';
import PropTypes from 'prop-types';

const WP = styled.div`
  transform: rotate(90deg);
  position: absolute;
  display: block;
  z-index: 1;
  top: 100px;
  left: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--sys-bg-surface-l2-bg);
  color: var(--sys-bg-surface-l2-fg);
`;
const WN = styled.div`
  transform: rotate(-90deg);
  position: absolute;
  display: block;
  z-index: 1;
  top: 100px;
  right: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--sys-bg-surface-l2-bg);
  color: var(--sys-bg-surface-l2-fg);
`;

//Custom Previous Arrow for the slider
export const PrevArrow = props => {
  return (
    <WP onClick={props.onClick} className="slick-arrow">
      <EA />
    </WP>
  );
};
//Custom Next Arrow for the slider
export const NextArrow = props => {
  return (
    <WN onClick={props.onClick} className="slick-arrow">
      <EA />
    </WN>
  );
};
//Method to configure slick carousel settings
export function getSlickSettings() {
  return {
    draggable: true,
    infinite: true,
    dots: true,
    autoplay: true,
    speed: 500,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
}

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};
NextArrow.propTypes = {
  onClick: PropTypes.func,
};
