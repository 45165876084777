export const stringsToTranslate = [
  'text.sun',
  'text.mon',
  'text.tue',
  'text.wed',
  'text.thu',
  'text.fri',
  'text.sat',
  'number.nd',
  'number.st',
  'number.th',
  'number.rd',
  'month.jan',
  'month.feb',
  'month.mar',
  'month.apr',
  'month.may',
  'month.jun',
  'month.jul',
  'month.aug',
  'month.sep',
  'month.oct',
  'month.nov',
  'month.dec',
  'text.live-caps',
  'text.under',
  'text.over',
  'text.create.a.bet',
];
