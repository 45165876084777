// Dependenceis
import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// Services
import { useInterval } from 'Services/hooks/core__interval';

// UI
import { FONT } from 'UI/globals/colours';
import { IN as IN_CORE } from 'CORE__UI/apps/PromotionsCarousel/core__slickCarousel';
import { IN as IN_LOCAL } from 'UI/apps/PromotionsCarousel/SlickCarousel';
import { FD as FD_LOCAL } from 'UI/apps/Carousel/Carousel';

// Globals
import { getRandomString } from '../globalfunctions/core__global-functions';

// Objects
import project from '../../project';

// Helpers
import { getComponent } from 'Services/core__imports';

// Local Components
const IN = getComponent(IN_LOCAL, IN_CORE);

export const CON = styled.div`
  width: 100%;
  position: relative;
  padding: ${({ containerPadding }) => containerPadding};
  margin-bottom: 8px;
  &:hover .slick-arrow {
    opacity: 1;
  }
  & .slick-arrow {
    opacity: 0;
  }
  ${({ hideNavigationCarousel }) =>
    hideNavigationCarousel &&
    css`
      &:hover .slick-arrow {
        opacity: 0;
      }
    `}
`;

const FD = styled.div`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 12px;
  ${({ border }) =>
    border &&
    css`
      border: 0px solid ${FONT.lightMute};
    `}
`;

const FLEX_DIV = getComponent(FD_LOCAL, FD);

const DOT = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 4px;
  padding: 5px;
  background-color: white;
  cursor: pointer;
`;
const DC = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 *
 * @param {*} children an array of jsx elements to be rendered inside the carousel
 * @param {*} nextArrow an object containing arrow component to be rendered as next arrow
 * @param {*} prevArrow an object containing arrow component to be rendered as prev arrow
 * @param {*} autoplay boolean, to autoplay the slides inside the carousel
 * @param {*} speed number, to define transition speed of the slides
 * @param {*} arrows boolean, flag to display prev/next arrows over the carousel
 * @param {*} rightMargin string, to enable space between two consecutive cards elements inside carousel
 * @param {*} infinite boolean, flag to set the infinite slide transition
 * @param {*} transformSpeed number, sets the transition speed for the elemnts inside slider
 * @param {*} width string, to set width of elements(cards) inside slider
 * @param {*} containerPadding applies padding to the main container
 * @param {*} dots to show nav dots, helps to navigate to specific slides
 * @param {*} activeDotIndicator bool, sets the index of each slide inside nav dots
 * @param {*} classnames class name for container and inner slider
 */

const Carousel = ({
  children: newChildren = [],
  arrows = false,
  nextArrow = {},
  prevArrow = {},
  autoplay = false,
  speed = 500,
  width = 'fit-content',
  rightMargin = 0,
  infinite = false,
  transformSpeed = 3000,
  containerPadding = 'unset',
  activeDotIndicator = false,
  dots = false,
  hideNavigationCarousel,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const [children, setChildren] = useState(newChildren);
  const [count, setCount] = useState(0);
  const [disableTransition, setTransitionDisable] = useState(true);
  const [eleWidth, setEleWidth] = useState(0);
  const [eleTotalWidth, setEleTotalWidth] = useState(0);
  const [showArrows, setShowNavArrow] = useState(true);
  const [classId, setClassId] = useState(null);
  const refs = useRef();
  const containerRef = useRef();
  const stateRef = useRef({
    activeIndex: activeIndex,
    eleWidth: eleWidth,
    count: count,
  });
  const setState = useCallback((func, name, value) => {
    stateRef.current = {
      ...stateRef.current,
      [name]: value,
    };
    func(value);
  }, []);

  //sets reference to width of each child element
  const register = useCallback(
    refName => ref => {
      if (refs.current) {
        refs.current[refName] = ref?.offsetWidth + rightMargin;
      } else {
        refs.current = {};
        refs.current[refName] = ref?.offsetWidth + rightMargin;
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setClassId(getRandomString(5));
  }, []);

  //add event listeners for web and mobile drag and swipe functionality
  useEffect(() => {
    let touchstartX;
    let touchendX;
    let handleGesture;
    if (classId) {
      const innerSlider = document.querySelector(`.inner-slider-${classId}`);
      if (innerSlider) {
        const onTouchStart = function (e) {
          !paused && setPaused(true); //stops slider when user touches any banner
          touchstartX = e.changedTouches[0].screenX;
        };
        const onTouchEnd = function (e) {
          touchendX = e.changedTouches[0].screenX;
          handleGesture();
          innerSlider.style = {};
        };
        const onMouseUp = function (e) {
          touchendX = e.clientX;
          handleGesture();
          innerSlider.style = {};
        };
        const onMouseDown = function (e) {
          touchstartX = e.clientX;
        };
        innerSlider.addEventListener('touchstart', onTouchStart, false);
        innerSlider.addEventListener('mousedown', onMouseDown, false);
        innerSlider.addEventListener('touchend', onTouchEnd, false);
        innerSlider.addEventListener('mouseup', onMouseUp, false);

        handleGesture = () => {
          const { activeIndex, eleWidth } = stateRef.current;
          if (touchendX < touchstartX) {
            setState(setActiveIndex, 'activeIndex', activeIndex + 1);
            setState(
              setEleWidth,
              'eleWidth',
              eleWidth + refs.current[activeIndex + 1]
            );
          }

          if (touchendX > touchstartX) {
            if (activeIndex > 0) {
              setState(setActiveIndex, 'activeIndex', activeIndex - 1);
              setState(
                setEleWidth,
                'eleWidth',
                eleWidth - refs.current[activeIndex - 1]
              );
            }
          }
        };
        //remove attached listeners on unmount
        return () => {
          innerSlider.removeEventListener('touchstart', onTouchStart);
          innerSlider.removeEventListener('touchend', onTouchEnd);
          innerSlider.removeEventListener('mousedown', onMouseDown);
          innerSlider.removeEventListener('mouseup', onMouseUp);
        };
      }
    }
  }, [classId, paused]); // eslint-disable-line react-hooks/exhaustive-deps

  //sets width of container div
  useEffect(() => {
    if (refs.current) {
      let totalWidth = 0;
      Object.keys(refs.current).map(key => {
        totalWidth = totalWidth + refs.current[key];
      });
      setEleTotalWidth(totalWidth);
    }
  }, [refs?.current]); // eslint-disable-line react-hooks/exhaustive-deps

  //(show navigation arrows) check if elements are contained inside container
  useEffect(() => {
    if (containerRef.current?.offsetWidth > eleTotalWidth) {
      setShowNavArrow(false);
    } else {
      setShowNavArrow(arrows);
    }
  }, [eleTotalWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  //append elements at back for infinite loop
  useEffect(() => {
    if (newChildren.length) {
      let appendChild = [...newChildren];
      if (infinite) {
        for (let i = 0; i <= 5; i++) {
          appendChild = [...appendChild, ...newChildren];
        }
      }
      setTimeout(() => {
        setChildren(appendChild);
        refs.current = {};
        setState(setCount, 'count', React.Children.count(newChildren));
      });
    }
  }, [newChildren]); // eslint-disable-line react-hooks/exhaustive-deps

  let timeOut;
  //set index of the active element in the carousel
  const updateIndex = newIndex => {
    if (timeOut) {
      return;
    }
    timeOut = setTimeout(() => {
      if (infinite) {
        if (
          newIndex > activeIndex && //checks if forward navigation is clicked
          newIndex <= count && //checks if current  index is not greater than total child elements
          eleWidth < eleTotalWidth / 7 //if slide reaches one complete loop over new children
        ) {
          setState(
            setEleWidth,
            'eleWidth',
            eleWidth + (refs.current?.[newIndex - 1] || 0)
          );
        }
        if (
          newIndex < activeIndex && //checks if previous navigation is clicked
          newIndex >= 0 && //check if current slide is last slide
          eleWidth >= 0 //if index reaches first slide, set transform back to total child width
        ) {
          setState(
            setEleWidth,
            'eleWidth',
            eleWidth - (refs.current?.[newIndex] || 0)
          );
        }
        if (newIndex < 0) {
          setTransitionDisable(false);
          setState(setEleWidth, 'eleWidth', Math.floor(eleTotalWidth / 7));
          newIndex = count;
        }
        if (newIndex > count) {
          setTransitionDisable(false);
          setState(setEleWidth, 'eleWidth', 0);
          newIndex = 0;
        }
      } else {
        if (newIndex >= count - 1) {
          newIndex = count - 1;
        }
        if (newIndex < 0) {
          newIndex = 0;
        }
        if (newIndex > activeIndex) {
          setState(
            setEleWidth,
            'eleWidth',
            eleWidth + refs.current?.[newIndex - 1]
          );
        }
        if (newIndex < activeIndex) {
          setState(
            setEleWidth,
            'eleWidth',
            eleWidth - (refs.current?.[newIndex] || 0)
          );
        }
      }
      setState(setActiveIndex, 'activeIndex', newIndex);
      setTransitionDisable(true);
      timeOut = null;
    }, [speed]);
  };

  const dotsTransition = index => {
    let calcWidth = 0;
    for (let i = 1; i <= index; i++) {
      calcWidth += refs.current[i];
    }
    setEleWidth(calcWidth);
    setActiveIndex(index);
    setTransitionDisable(true);
  };

  //enable infinite slide
  useEffect(() => {
    if (infinite) {
      const nextChild = [...children];
      if (activeIndex >= count) {
        const getChild = nextChild.splice(0, count);
        setChildren([...nextChild, ...getChild]);
        updateIndex(activeIndex + 1);
      }
      if (activeIndex < 0) {
        const getChild = nextChild.splice(
          count,
          React.Children.count(children)
        );
        setChildren([...getChild, ...newChildren]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex >= count || activeIndex <= 0]);

  //set interval to autoplay carousel
  useInterval(() => {
    if (!paused && autoplay) {
      updateIndex(activeIndex + 1);
    }
  }, (infinite && transformSpeed) || null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        paused && setPaused(false); //restart carousel autoplay when user clicks outside carousel
      }
    }
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [containerRef, paused]);

  //return if no child is present
  if (!newChildren.length) {
    return null;
  }

  return (
    <>
      <CON
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
        containerPadding={containerPadding}
        className={`carousel-slider-${classId} carousel-slider`}
        hideNavigationCarousel={hideNavigationCarousel}
      >
        <>
          {showArrows &&
            React.cloneElement(prevArrow, {
              onClick: () => {
                updateIndex(activeIndex - 1);
              },
            })}
          <IN
            activeIndex={activeIndex}
            widthOfEle={eleWidth}
            disableTransition={disableTransition}
            transitionSpeed={speed}
            ref={containerRef}
            className={`inner-slider-${classId} inner-slider`}
          >
            {children.length
              ? children.map((child, index) => {
                  return (
                    <FLEX_DIV
                      border={project.carousal}
                      style={{ width: width, marginRight: `${rightMargin}px` }}
                      key={index}
                      ref={register(index)}
                      className={
                        activeIndex === index
                          ? 'slick-slide slide-active'
                          : 'slick-slide'
                      }
                    >
                      {child}
                    </FLEX_DIV>
                  );
                })
              : newChildren.map((child, index) => {
                  return (
                    <FLEX_DIV
                      border={project.carousal}
                      style={{ width: width, marginRight: `${rightMargin}px` }}
                      key={index}
                      ref={register(index)}
                      className={
                        activeIndex === index
                          ? 'slick-slide slide-active'
                          : 'slick-slide'
                      }
                    >
                      {child}
                    </FLEX_DIV>
                  );
                })}
          </IN>
          {showArrows &&
            React.cloneElement(nextArrow, {
              onClick: () => {
                updateIndex(activeIndex + 1);
              },
            })}
        </>
        <DC className="carousel-indicators">
          {dots &&
            newChildren?.map((ele, index) => (
              <DOT
                onClick={() => dotsTransition(index)}
                className={
                  activeIndex === index ? 'active-dot' : 'carousel-dots'
                }
                key={index}
                data-test="nav-dots"
              >
                {(activeDotIndicator && index + 1) || null}
              </DOT>
            ))}
        </DC>
      </CON>
    </>
  );
};

Carousel.propTypes = {
  children: PropTypes.array,
  nextArrow: PropTypes.node,
  prevArrow: PropTypes.node,
  autoplay: PropTypes.bool,
  speed: PropTypes.number,
  arrows: PropTypes.bool,
  rightMargin: PropTypes.number,
  infinite: PropTypes.bool,
  width: PropTypes.string,
  containerPadding: PropTypes.string,
  transformSpeed: PropTypes.number,
  dots: PropTypes.bool,
  activeDotIndicator: PropTypes.bool,
  hideNavigationCarousel: PropTypes.bool,
};

export default Carousel;
