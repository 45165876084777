//function to replace html entity i.e &amp
const replaceHtmlEntities = content => {
  const newContent = [];
  Array.isArray(content) &&
    content.forEach(obj => {
      const { appConfig: { footertext } = {} } = obj;
      if (footertext)
        obj.appConfig.footertext = footertext.split('&amp;').join('&');
      newContent.push(obj);
    });

  return newContent;
};

export default replaceHtmlEntities;
