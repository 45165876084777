import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OddsButton from 'UI/buttons/OddsButton';
import { configIcon } from 'Services/icons/core__config';

import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getOdds } from 'Services/global/core__american-format';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import project from '../../../../../../project';
import { Translations } from '../../../core__promotions-grid-app';
import moment from 'moment';
import { getComponent } from 'Services/core__imports';
import {
  COB,
  ID as ID_CORE,
  DTitle as DTITLE_CORE,
  DES,
  DOC as DOC_CORE,
  DITEM as DITEM_CORE,
  DEventName,
  DEN as DEN_CORE,
  LTH,
  LT,
  CreateBetIcon,
  CreateBetContainer,
  CreateBetText as CreateBetText_Core,
  LiveScore,
  ScoreContainer,
} from 'CORE__UI/apps/PromotionsGridApp/core__promotions-grid-react';
import {
  DITEM as DITEM_LOCAL,
  DTitle as DTITLE_LOCAL,
  ID as ID_LOCAL,
  DEN as DEN_local,
  DOC as DOC_local,
  CreateBetText as CreateBetText_Local,
} from 'UI/apps/PromotionsGridApp/PromotionsGridReact';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { useInterval } from 'Services/hooks/core__interval';

const DITEM = DITEM_LOCAL || DITEM_CORE;
const DTitle = getComponent(DTITLE_LOCAL, DTITLE_CORE);
const ID = getComponent(ID_LOCAL, ID_CORE);
const DEN = getComponent(DEN_local, DEN_CORE);
const DOC = getComponent(DOC_local, DOC_CORE);
const CreateBetText = getComponent(CreateBetText_Local, CreateBetText_Core);

export const DynamicPromotionsItem = ({
  promoConfig,
  getBetslipSelectionList,
  index,
  oddsType,
  callBetslip,
  dataFromSocket,
}) => {
  const [score, setTeamScore] = useState({});
  const [eventSocketListener, setEventSocketListener] = useState(null);
  const [isTimerActive, setTimerActive] = useState(false);
  const [inPlayTime, setInPlayTime] = useState(null);
  const [gameData, setGameData] = useState({});
  const [timer, setTimer] = useState(null);

  /**
   * Method to form kick of time
   * @param { string } scheduledStart
   */
  const getKickOfTime = (scheduledStart, state) => {
    if (project.JpTimeFormat) {
      if (state === GLOBAL_CONSTANTS.INPLAY) {
        return Translations.get('text.live-caps');
      }
      return getDate(scheduledStart);
    } else {
      const day = moment(scheduledStart).day();
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      [
        Translations.get('text.sun'),
        Translations.get('text.mon'),
        Translations.get('text.tue'),
        Translations.get('text.wed'),
        Translations.get('text.thu'),
        Translations.get('text.fri'),
        Translations.get('text.sat'),
      ];
      return `${days[day]}, ${moment(scheduledStart).format(
        project.timeFormats.search.tertiary || 'HH:MM'
      )}`;
    }
  };

  //get date format for japanese site
  const dateOrdinal = d =>
    d +
    (31 === d || 21 === d || 1 === d
      ? Translations.get('number.st')
      : 22 === d || 2 === d
      ? Translations.get('number.nd')
      : 23 === d || 3 === d
      ? Translations.get('number.rd')
      : Translations.get('number.th'));

  //get date format for japanese site

  const getDate = scheduledStart => {
    const date = moment(scheduledStart);
    const day = dateOrdinal(date.date());
    const hour = date.format('H');
    const minutes = date.format('mm');
    const month = Translations.get(`month.${date.format('MMM')}`);

    const time = `${hour}:${minutes}`;

    return `${month} ${day}, ${time}`;
  };

  const [dynamicData, setDynamicData] = useState(promoConfig?.dynamicData);
  const [eventNameSeperated, setSeperatedEventName] = useState([]);
  const isLive =
    project.JpTimeFormat && dynamicData?.state === GLOBAL_CONSTANTS.INPLAY;

  useEffect(() => {
    if (promoConfig.dynamicData) {
      setDynamicData(promoConfig.dynamicData);
    }
  }, [promoConfig]);

  useEffect(() => {
    dynamicData?.eventName &&
      setSeperatedEventName(dynamicData?.eventName.split(/\s[v@]\s/));
  }, [dynamicData?.eventName]);

  useEffect(() => {
    if (dataFromSocket) {
      listenMarketSocket(dataFromSocket);
    }
  }, [dataFromSocket]);
  const listenMarketSocket = wsData => {
    setDynamicData(currentDynamicData => {
      let updated = false;
      const updatedDynamicData = { ...currentDynamicData };
      if (wsData.active !== currentDynamicData.active) {
        updatedDynamicData.active = wsData.active;
        updated = true;
      }

      //if update seelctions
      if (wsData.updatedSelections) {
        const incomingSelections = [...wsData.selections];
        incomingSelections.forEach(incoming => {
          updatedDynamicData.selections &&
            updatedDynamicData.selections.forEach((current, s_index) => {
              if (current.selectionId === incoming.id) {
                const currentPrice = {
                  decimal: current.decimal,
                  fractional: current.fractional,
                };

                const updatedPrice = incoming.price.decimal && {
                  ...currentPrice,
                  decimal: incoming.price.decimal,
                  fractional: incoming.price.fractional,
                };

                let oddsTrend = null;

                if (updatedPrice) {
                  const priceDiff =
                    parseFloat(updatedPrice.decimal) -
                    parseFloat(currentPrice.decimal || 0);
                  if (priceDiff > 0) {
                    oddsTrend = GLOBAL_CONSTANTS.ODDS_INCREASE;
                  } else if (priceDiff < 0) {
                    oddsTrend = GLOBAL_CONSTANTS.ODDS_DECREASE;
                  }
                }

                const newSelection = {
                  ...current,
                  ...updatedPrice,
                  active:
                    typeof incoming.active === 'boolean'
                      ? incoming.active
                      : current.active,
                  oddsTrend,
                };

                //updating the main variable here
                updatedDynamicData.selections[s_index] = newSelection;
                updated = true;
              }
            });
        });
      }
      if (updated) {
        return updatedDynamicData;
      } else {
        return currentDynamicData;
      }
    });
  };
  useEffect(() => {
    subscribeEventSocket();
    listenEventSocket();
    return () => {
      eventSocketListener && eventSocketListener.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const subscribeEventSocket = () => {
    if (dynamicData) {
      const subscriptionObject = {
        categoryRef: dynamicData.ref,
        providerRef: dynamicData.providerRef,
        sourceKey: dynamicData.sourceKey,
      };
      PubSub.emit(PubsubEvents.SOCKET_EVENT_SUBSCRIBER, subscriptionObject);
    }
  };
  const listenEventSocket = () => {
    const listener = PubSub.listen(PubsubEvents.SOCKET_EVENT_LISTEN, wsData => {
      //handle event update here
      if (wsData.sourceKey === dynamicData?.sourceKey) {
        if (wsData.catRef === GLOBAL_CONSTANTS.TENNIS_REF) {
          const tennisScore = wsData?.score?.split(':');
          setTeamScore({
            home: tennisScore[0],
            away: tennisScore[1],
          });
        } else if (wsData.catRef === GLOBAL_CONSTANTS.DARTS_REF) {
          setTeamScore({
            home: wsData?.game?.home,
            away: wsData?.game?.away,
          });
        } else if (wsData.catRef === GLOBAL_CONSTANTS.CRICKET_REF) {
          if (wsData.game?.score?.length) {
            const parsed = {
              home: '',
              away: '',
            };

            wsData.game.score.forEach(inning => {
              if (inning?.home) {
                parsed['home'] += inning.home + ' ' || ' ';
              }
              if (inning?.away) {
                parsed['away'] += inning.away + ' ' || ' ';
              }
            });

            setTeamScore({
              home: parsed.home.trim(),
              away: parsed.away.trim(),
            });
          } else {
            setTeamScore({
              home: wsData?.game?.home,
              away: wsData?.game?.away,
            });
          }
        } else {
          if (
            wsData?.game &&
            (wsData?.game?.home !== score.home ||
              wsData?.game?.away !== score.away)
          ) {
            setTeamScore({
              home: wsData?.game?.home,
              away: wsData?.game?.away,
            });
          }
        }

        if (dynamicData.state === GLOBAL_CONSTANTS.INPLAY) {
          //for some data there's minutes instead of minute
          if (wsData?.minutes) {
            wsData.minute = wsData?.minutes;
          }
          if (wsData?.seconds) {
            wsData.second = wsData?.seconds;
          }

          if (wsData?.minute !== undefined && wsData?.second !== undefined) {
            setTimerActive(true);

            if (
              wsData.catRef === GLOBAL_CONSTANTS.SOCCER &&
              wsData.extratime &&
              wsData?.minute > 90
            ) {
              setInPlayTime(
                parseInt(wsData?.minute - 90) * 60 + parseInt(wsData.second)
              );
            } else {
              setInPlayTime(
                parseInt(wsData.minute) * 60 + parseInt(wsData.second)
              );
            }
          }
        }
        //set game data for period and state
        const updatedGameData = {};
        if (wsData.period) {
          updatedGameData['period'] = wsData.period;
        }
        if (wsData.state) {
          updatedGameData['state'] = wsData.state;
        }
        setGameData(updatedGameData);
      }
    });
    setEventSocketListener(listener);
  };

  useInterval(() => {
    if (
      inPlayTime !== null &&
      gameData.period !== GLOBAL_CONSTANTS.BREAK_TIME &&
      gameData.state !== GLOBAL_CONSTANTS.COMPLETED
    ) {
      if (dynamicData.ref === GLOBAL_CONSTANTS.BASKETBALL_REF) {
        inPlayTime > 0 && setInPlayTime(inPlayTime - 1);
      } else {
        setInPlayTime(inPlayTime + 1);
      }
    }
  }, 1000);

  useEffect(() => {
    if (isTimerActive) {
      const date =
        ('0' + Math.floor(inPlayTime / 60)).slice(-2) +
        ':' +
        ('0' + Math.floor(inPlayTime % 60)).slice(-2);
      setTimer(date);
    }
  }, [isTimerActive, inPlayTime]);

  const redirectPage = url => {
    if (url) {
      window.location.href = url;
    }
  };
  return (
    <React.Fragment>
      <DITEM
        image={promoConfig['background-image']}
        size={promoConfig['background-size']}
        position={promoConfig['background-position']}
        pointer={false}
        carousal={project?.carousal}
        onClick={() => redirectPage(dynamicData.eventUrl)}
      >
        {promoConfig['title'] && (
          <DES
            customColor={
              promoConfig['title-color'] &&
              promoConfig['title-color'] !== '' &&
              promoConfig['title-color']
            }
            carousal={project?.carousal}
          >
            {promoConfig['title']}
          </DES>
        )}
        {promoConfig['description'] && <DES>{promoConfig['description']}</DES>}
        {dynamicData?.leagueName && (
          <DEventName>
            <React.Fragment>
              <DOC carousal={project?.carousal}>
                <LTH
                  href={`${dynamicData.subCatUrl}`}
                  onClick={e => e.stopPropagation()}
                  live={isLive}
                >
                  <LT>{dynamicData?.leagueName}</LT>
                </LTH>
                {promoConfig?.centerkickoff && (
                  <ID carousal={project?.carousal} live={isLive}>
                    {promoConfig.type === GLOBAL_CONSTANTS.DYNAMIC &&
                      dynamicData?.scheduledStart &&
                      dynamicData?.state !== 'IN_PLAY' &&
                      getKickOfTime(
                        dynamicData.scheduledStart,
                        dynamicData?.state
                      )}
                    {promoConfig.type === GLOBAL_CONSTANTS.DYNAMIC &&
                      dynamicData?.state === 'IN_PLAY' &&
                      timer &&
                      timer}
                  </ID>
                )}
              </DOC>
            </React.Fragment>
            {!promoConfig?.centerkickoff && (
              <React.Fragment>
                {ID && (
                  <ID carousal={project?.carousal}>
                    <React.Fragment>
                      {dynamicData?.scheduledStart &&
                        moment(dynamicData.scheduledStart).format(
                          project.timeFormats.promotion.primary || 'DD:MM'
                        )}
                    </React.Fragment>
                  </ID>
                )}
              </React.Fragment>
            )}
          </DEventName>
        )}
        {eventNameSeperated.length && (
          <React.Fragment>
            <ScoreContainer>
              <DTitle carousal={project?.carousal}>
                {eventNameSeperated[0]}
              </DTitle>
              {dynamicData?.state === GLOBAL_CONSTANTS.INPLAY && (
                <LiveScore>{score.home}</LiveScore>
              )}
            </ScoreContainer>
            {eventNameSeperated[1] && (
              <ScoreContainer>
                <DTitle carousal={project?.carousal}>
                  {eventNameSeperated[1]}
                </DTitle>
                {dynamicData?.state === GLOBAL_CONSTANTS.INPLAY && (
                  <LiveScore>{score.away}</LiveScore>
                )}
              </ScoreContainer>
            )}
            {/* </a> */}
            <DEN carousal={project?.carousal} navigation={true}>
              {dynamicData.marketName}
            </DEN>
          </React.Fragment>
        )}

        {COB && (
          <COB carousal={project?.carousal}>
            {dynamicData?.selections &&
              dynamicData.selections.map((selection, selectionIndex) => {
                return (
                  <OddsButton
                    carousal={project?.carousal}
                    data-test={'odds-button' + index + selectionIndex}
                    key={selectionIndex}
                    odds={
                      oddsType === GLOBAL_CONSTANTS.FRACTIONAL
                        ? selection.fractional
                        : oddsType === GLOBAL_CONSTANTS.DECIMAL
                        ? parseDecimalPlaces(selection.decimal)
                        : getOdds(selection.decimal, true)
                    }
                    label={
                      selection.typeRef &&
                      Translations.get(`text.${selection.typeRef}`)
                    }
                    labelPos={'up'}
                    selected={
                      getBetslipSelectionList?.indexOf(selection.selectionId) >
                      -1
                    }
                    inactive={!dynamicData.active ? true : !selection.active}
                    onClick={e => {
                      e.stopPropagation();
                      !promoConfig?.dynamicData?.isSuspended &&
                        callBetslip(selection.selectionId);
                    }}
                    oddsTrend={selection.oddsTrend}
                  />
                );
              })}
          </COB>
        )}

        {promoConfig.showbetbuilder &&
          dynamicData &&
          dynamicData.ref === GLOBAL_CONSTANTS.SOCCER &&
          dynamicData?.state !== GLOBAL_CONSTANTS.INPLAY && (
            <CreateBetContainer
              onClick={e => {
                e.stopPropagation();
                redirectPage(`${dynamicData.eventUrl}?tab=CAB`);
              }}
            >
              <CreateBetIcon size={16} iconName={configIcon.CREATEBET} />
              <CreateBetText>
                {Translations.get('text.create.a.bet')}
              </CreateBetText>
            </CreateBetContainer>
          )}
      </DITEM>
    </React.Fragment>
  );
};

DynamicPromotionsItem.propTypes = {
  promoConfig: PropTypes.object,
  getBetslipSelectionList: PropTypes.array,
  oddsType: PropTypes.string,
  index: PropTypes.number,
  hideCategories: PropTypes.bool,
  callBetslip: PropTypes.func,
  dataFromSocket: PropTypes.object,
};
