import styled, { css } from 'styled-components';
import { GREYS, BRAND, FONT, FUNCTIONAL } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import I from 'UI/globals/Icons';

export const PromotionsGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({ hideCategories }) => (hideCategories ? '1024px' : '100%')};
  margin-inline: auto;
  user-select: text;
  &:first-child {
    margin-top: ${({ hideCategories }) => (hideCategories ? '16px' : '8px')};
  }
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
    width: 100%;
  }
`;
export const ItemContainer = styled.div`
  cursor: pointer;
  width: 304px;
  height: 200px;
  background-image: linear-gradient(
    to right,
    #000000 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 56px;
  margin-bottom: ${({ hideCategories }) => (hideCategories ? '40px' : '0')};

  &:first-child,
  &:nth-child(3n + 1) {
    ${({ disableLeftMargin }) => {
      return (
        disableLeftMargin &&
        css`
          margin: inherit !important;
        `
      );
    }}
  }

  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
    margin-inline: auto;
    margin-block: ${({ hideCategories }) => (hideCategories ? '16px' : '0')};
    &:first-child,
    &:nth-child(4n) {
      margin-left: auto;
    }
  }
`;

export const Item = styled.div`
  ${({ image, size, position }) => {
    return (
      image &&
      css`
        background-image: url('${image}');
        background-size: ${size};
        background-position: ${position};
      `
    );
  }}
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  @media (max-width: ${BREAKPOINT_S}) {
    width: 304px;
    height: 200px;
  }
`;

export const DITEM = styled(Item)`
  justify-content: space-between;
`;

export const EventName = styled.div`
  color: ${GREYS.white};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${GREYS.black};
    `}
`;

export const DEventName = styled(EventName)`
  margin-top: unset;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  text-align: left;
  margin-top: 8px;
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${GREYS.black};
    `}
`;
export const DEN = styled(DEventName)`
  margin-bottom: 12px;
  color: ${GREYS.white};
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${GREYS.black};
      margin-bottom: 0px;
    `}
  ${({ carousal, navigation }) =>
    carousal &&
    navigation &&
    css`
      margin-bottom: 0px;
      margin-top: 12px;
    `}
`;

export const Title = styled.div`
  color: ${({ color }) => (color ? color : GREYS.white)};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  width: 80%;
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${GREYS.black};
    `}
`;
export const Footer = styled.a`
  width: 100%;
  background-color: rgba(34, 34, 34, 0.4);
  min-height: 30px;
  position: absolute;
  bottom: 0;
  color: ${GREYS.white};
  font-size: 10px;
  padding: 8px 16px;
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${GREYS.black};
      opacity: 80%;
    `}
`;
export const Description = styled.div`
  font-size: 14px;
  color: ${GREYS.white};
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${GREYS.black};
    `}
`;
//container for odds buttons
export const COB = styled.div`
  display: flex;
  button {
    height: 40px;
  }
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${GREYS.black};
    `}
`;

//Container for live and start time
export const ID = styled.div`
  display: flex;
  color: ${GREYS.white};
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: right;
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${GREYS.black};
    `}
  ${({ live }) =>
    live &&
    css`
      background-color: ${FUNCTIONAL.positiveL};
      color: ${FUNCTIONAL.positiveD};
      border: 1px solid ${FUNCTIONAL.positiveD};
      font-weight: 700;
      padding: 4px;
      letter-spacing: 0.32px;
      font-size: 9px;
      border-radius: 4px;
      line-height: 12px;
      max-height: 23px;
    `}
`;

//league text header
export const LTH = styled.a`
  max-width: 176px;
  ${({ live }) =>
    live &&
    css`
      max-width: 224px;
    `}
`;

//league text
export const LT = styled.div`
  overflow-wrap: anywhere;
`;

export const DTitle = styled.div`
  color: ${({ color }) => (color ? color : GREYS.white)};
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${FONT.secondary};
    `}
`;
export const DES = styled.div`
  color: ${GREYS.white};
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${FONT.secondary};
    `}
`;
export const Wrap = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  .carousel-dots {
    background-color: ${({ theme }) =>
      theme.dark ? BRAND.favourite : GREYS.silverD};
    width: 16px;
    height: 3px;
    padding: 0px 10px;
    margin: 5px;
    border-radius: unset;
    opacity: 0.25;
  }
  .carousel-indicators {
    justify-content: end;
    padding: 5px 0px 0px;
  }
  .active-dot {
    background-color: ${({ theme }) =>
      theme.dark ? BRAND.favourite : GREYS.silverD};
    width: 16px;
    height: 3px;
    padding: 0px 20px;
    margin: 5px;
    border-radius: unset;
    opacity: 0.75;
  }
`;
export const A = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
//Dynamic odds event name and time container
export const DOC = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: space-between;
  margin-bottom: 8px;
  color: ${GREYS.white};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 16px;
  ${({ carousal }) =>
    carousal &&
    css`
      color: ${GREYS.black};
    `}
`;

export const CreateBetIcon = styled(I)`
  color: ${BRAND.secondary};
`;

export const CreateBetContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateBetText = styled.span`
  color: ${GREYS.white};
  margin-left: 4px;
`;

export const LiveScore = styled.span`
  color: ${BRAND.secondary};
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: right;
`;

export const ScoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
