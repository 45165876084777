import styled, { css } from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';

// Container for the carousel cards items
export const IN = styled.div`
  display: flex;
  flex-shrink: 0;
  transition: ${({ disableTransition, transitionSpeed }) =>
    `all ${(disableTransition && transitionSpeed) || 0}ms ease 0s`};
  transform: ${({ widthOfEle }) => `translate3d(-${widthOfEle}px,0px,0px)`};
`;

//div for event name and time
export const FD = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => (props.isFooterText ? '4px' : '12px')};
`;

//Container for live and start time
export const ID = styled.div`
  display: flex;
`;

//div to display market and event names
export const SD = styled.div`
  margin-bottom: ${props => props.isFooterText && '12px'};
  font-size: 18px;
  font-weight: bold;
  width: 70%;
`;

//container for dynamic carousel
export const ZD = styled.div`
  margin-top: 8px;
  display: inline-block;
  width: 304px;
  min-height: 200px;
  background-color: ${({ backgroundColor, theme }) =>
    theme.dark ? FONT.darkMute : backgroundColor};
  ${props =>
    props.imageurl &&
    css`
      background-image: url(${props.imageurl});
    `}
  color: ${props => props.fontColor};
  overflow: hidden;
  button {
    width: 85px;
  }
  background-position: bottom right;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  background-repeat: no-repeat;
`;

//container for odds buttons
export const COB = styled.div`
  display: flex;
  margin: ${props => (props.isFooterText ? '0 16px 44px' : '0 16px 4px')};
  button {
    height: 40px;
  }
`;

//div for promotions footer
export const FP = styled.div`
  height: 30px;
  padding: 0 16px;
  background-color: rgba(34, 34, 34, 0.4);
  display: flex;
  align-items: center;
  color: ${({ theme }) => (theme.dark ? GREYS.white : FONT.light)};
  font-size: 10px;
  margin-top: ${props => props.isFooterText && '-44px'};
`;

export const CCD = styled.div`
  padding: 16px 16px 0px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  & > div:first-child {
    color: ${({ promoType, theme }) =>
      theme.dark && promoType === 'dynamic' ? FONT.primary : GREYS.white};
  }
  ${({ promoType, theme }) =>
    theme.dark &&
    promoType === 'dynamic' &&
    css`
      & > div:nth-child(3) {
        color: ${FONT.lightMute};
      }
    `}
`;

export const W = styled.div`
  cursor: pointer;
  .carousel-dots {
    background-color: white;
  }
  .carousel-dots {
    background-color: #f9d840;
    width: 16px;
    height: 3px;
    padding: 0px 10px;
    margin: 5px;
    border-radius: unset;
    opacity: 0.25;
  }
  .carousel-indicators {
    justify-content: end;
    padding: 5px 0px 0px;
  }
  .active-dot {
    background-color: #f9d840;
    width: 16px;
    height: 3px;
    padding: 0px 20px;
    margin: 5px;
    border-radius: unset;
    opacity: 0.75;
  }
`;

//div for dynamic event name and time
export const DFD = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

//div to display market and event names
export const DSD = styled.div`
  font-weight: bold;
`;
