import {
  PromotionsGridContainer,
  Item,
  ItemContainer,
  Footer,
  Description,
  EventName,
  Title,
} from 'CORE__UI/apps/PromotionsGridApp/core__promotions-grid-react';
import styled from 'styled-components';
import { BREAKPOINT_S } from '../../globals/breakpoints';
const PromotionsGridContainer_GDWN = styled(PromotionsGridContainer)`
  @media (max-width: ${BREAKPOINT_S}) {
    &:first-child {
      margin-top: 0;
    }
  }
`;
const ItemContainer_GDWN = styled(ItemContainer)`
  margin-left: 2px;
  @media (max-width: ${BREAKPOINT_S}) {
    &:nth-child(n) {
      margin-left: auto;
    }
  }
`;
export {
  PromotionsGridContainer_GDWN as PromotionsGridContainer,
  Item,
  ItemContainer_GDWN as ItemContainer,
  Footer,
  Description,
  EventName,
  Title,
};
