import React from 'react';
import ReactDOM from 'react-dom';
import ScDecoder from 'Services/json/core__decoder';
import { checkEventsHeaderAppActive } from 'Services/core__services';
import replaceHtmlEntities from './component/helper/core__promotions-grid-utils';
import PromotionsGridAppReact from './component/core__promotions-grid-react';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { stringsToTranslate } from './core__promotions-grid-translations';
export const Translations = new TranslationsProvider('PromotionsGridApp');

class PromotionsGridApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      return;
    }
    if (this.appConfig) {
      this.appConfig.eventsHeaderActive = checkEventsHeaderAppActive(
        this.appSection
      );
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    const data =
      reduxSelector && new ScDecoder(JSON.parse(reduxSelector.innerHTML));
    this.appConfig.items = replaceHtmlEntities(data?.items);
    this.start(data, reduxSelector);
  }

  start(data, reduxSelector) {
    Translations.setAll(data);
    const ReactInstance = this.getReactApp();
    const jsx = <ReactInstance appConfig={this.appConfig} />;

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    PromotionsGridAppReact.strToTranslate = stringsToTranslate;
    return PromotionsGridAppReact;
  }
}

export default PromotionsGridApp;
