import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { PromotionsDisplay } from '../PromotionsDisplay/core__promotionsDisplay';
import Carousel from 'Services/carousel/core__carousel';
import Constants from '../../../../../../project';
import { getSlickSettings } from '../../helper/core__carouselConfig';
import { Wrap } from 'CORE__UI/apps/PromotionsGridApp/core__promotions-grid-react';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getCookie } from 'Services/cookie/core__cookies';

const PromotionsContainer = ({
  carouselActive,
  items,
  getBetslipSelectionList,
  oddsType,
  hideCategories,
  currencyCode,
  fallbackUrl,
  disableLeftMargin,
}) => {
  const settings = getSlickSettings();
  const [dataFromSocket, setDataFromSocket] = useState({}); //pubsub doesn't work in carousel that's why here
  const [filteredMarket, setFilteredMarket] = useState(
    Constants?.carouselTranslation ? [] : items
  );
  const filteredMarketRef = useRef(filteredMarket);
  useEffect(() => {
    //listen to market socket
    const marketSocket = PubSub.listen(
      PubsubEvents.SOCKET_MARKET_LISTEN,
      wsData => {
        setDataFromSocket(currentSocketData => {
          const updatedSocketData = { ...currentSocketData };
          let updated = false;
          if (items && items.length > 0) {
            items.forEach(({ appConfig }) => {
              if (parseInt(appConfig.marketid) === wsData.id) {
                updatedSocketData[appConfig.marketid] = wsData;
                updated = true;
              }
            });
          }
          if (updated) {
            return updatedSocketData;
          } else {
            return currentSocketData; //no update
          }
        });
      }
    );

    const marketIds =
      items &&
      items
        .filter(({ appConfig }) => appConfig.marketid)
        .map(({ appConfig }) => appConfig.marketid);

    if (marketIds && marketIds.length > 0) {
      PubSub.emit(PubsubEvents.SOCKET_MARKET_SUBSCRIBER, marketIds);
    }
    return () => {
      marketSocket?.unsubscribe();
    };
  }, [items]);

  useEffect(() => {
    const filterExpiredMarket = items.filter(item => {
      const itm = JSON.parse(JSON.stringify(item));
      if (
        (!itm?.appConfig?.dynamicData &&
          itm?.appConfig?.type === GLOBAL_CONSTANTS.DYNAMIC) ||
        (Constants.carouselTranslation &&
          ((getCookie('localeKey') &&
            itm?.appConfig?.locale !== getCookie('localeKey')) ||
            !itm?.appConfig?.locale))
      ) {
        return false;
      }
      return true;
    });
    filteredMarketRef.current = filterExpiredMarket;
    setFilteredMarket(filterExpiredMarket);
  }, [items]);

  useEffect(() => {
    if (Object.keys(dataFromSocket).length > 0) {
      const filterExpiredMarket = filteredMarketRef.current?.filter(market => {
        const getMargetWithId = dataFromSocket[market?.appConfig?.marketid];
        if (getMargetWithId?.state === GLOBAL_CONSTANTS.COMPLETED) {
          return false;
        }
        return true;
      });
      filteredMarketRef.current = filterExpiredMarket;
      setFilteredMarket(filterExpiredMarket);
    }
  }, [dataFromSocket]);

  if (carouselActive) {
    return (
      <React.Fragment>
        <Wrap>
          <Carousel {...settings} arrows={true} rightMargin={8}>
            {filteredMarket.map((promo, index) => {
              const promoConfig = promo.appConfig;
              return (
                <PromotionsDisplay
                  promoConfig={promoConfig}
                  key={index}
                  getBetslipSelectionList={getBetslipSelectionList}
                  index={index}
                  oddsType={oddsType}
                  hideCategories={hideCategories}
                  dataFromSocket={dataFromSocket}
                  currencyCode={currencyCode}
                  fallbackUrl={fallbackUrl}
                  disableLeftMargin={disableLeftMargin}
                />
              );
            })}
          </Carousel>
        </Wrap>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {filteredMarket.map((promo, index) => {
          const promoConfig = promo.appConfig;
          return (
            <PromotionsDisplay
              promoConfig={promoConfig}
              key={index}
              getBetslipSelectionList={getBetslipSelectionList}
              index={index}
              oddsType={oddsType}
              hideCategories={hideCategories}
              currencyCode={currencyCode}
              fallbackUrl={fallbackUrl}
              disableLeftMargin={disableLeftMargin}
            />
          );
        })}
      </React.Fragment>
    );
  }
};

export { PromotionsContainer };

PromotionsContainer.propTypes = {
  promoConfig: PropTypes.object,
  getBetslipSelectionList: PropTypes.array,
  carouselActive: PropTypes.bool,
  items: PropTypes.array,
  oddsType: PropTypes.string,
  hideCategories: PropTypes.bool,
  currencyCode: PropTypes.string,
  fallbackUrl: PropTypes.string,
  disableLeftMargin: PropTypes.bool,
};
