import styled, { css } from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';
import {
  FD,
  ID,
  SD,
  ZD,
  COB,
  FP,
  CCD,
  W,
} from 'CORE__UI/apps/PromotionsCarousel/core__slickCarousel';
const ZD_ex = styled(ZD)`
  background-color: ${FONT.darkMute};
`;

const FP_ex = styled(FP)`
  color: ${GREYS.white};
`;

const CCD_ex = styled(CCD)`
  color: ${GREYS.white};
  & > div:first-child {
    color: ${props =>
      props.promoType === 'dynamic' ? FONT.primary : GREYS.white};
  }
  ${props =>
    props.promoType === 'dynamic' &&
    css`
      & > div:nth-child(2) {
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
      }
    `}
  ${props =>
    props.promoType === 'dynamic' &&
    css`
      & > div:nth-child(3) {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        color: ${FONT.lightMute};
      }
    `}
`;
export { FD, ID, SD, ZD_ex as ZD, COB, FP_ex as FP, CCD_ex as CCD, W };
